<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">Nucleo Icons</h2>
          <h5 class="description">
            Now UI Kit comes with 100 custom icons made by our friends from
            NucleoApp. The official package contains over 2.100 thin icons which
            are looking great in combination with Now UI Kit Make sure you check
            all of them and use those that you like the most.
          </h5>
          <!-- <div class="nucleo-container">
                    <img v-lazy="'assetsimg/nucleo.svg'" alt="">
                </div> -->
          <a
            href="https://demos.creative-tim.com/now-ui-kit/nucleo-icons.html"
            class="btn btn-primary btn-round btn-lg"
            target="_blank"
            >View Demo Icons</a
          >
          <a
            href="https://nucleoapp.com/?ref=1712"
            class="btn btn-primary btn-simple btn-round btn-lg"
            target="_blank"
            >View All Icons</a
          >
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="icons-container">
            <i class="now-ui-icons ui-1_send"></i>
            <i class="now-ui-icons ui-2_like"></i>
            <i class="now-ui-icons transportation_air-baloon"></i>
            <i class="now-ui-icons text_bold"></i>
            <i class="now-ui-icons tech_headphones"></i>
            <i class="now-ui-icons emoticons_satisfied"></i>
            <i class="now-ui-icons shopping_cart-simple"></i>
            <i class="now-ui-icons objects_spaceship"></i>
            <i class="now-ui-icons media-2_note-03"></i>
            <i class="now-ui-icons ui-2_favourite-28"></i>
            <i class="now-ui-icons design_palette"></i>
            <i class="now-ui-icons clothes_tie-bow"></i>
            <i class="now-ui-icons location_pin"></i>
            <i class="now-ui-icons objects_key-25"></i>
            <i class="now-ui-icons travel_istanbul"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
